<template>
  <div class="school-data-cbt">
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <div class="mb-6">
      <span class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text">
        CBT (Computers Based Test)
      </span>
      <small class="text-subtitle-2">Ujian CBT</small>
    </div>

    <div>
      <div v-if="!isLoadingCard">
        <v-row class="match-height">
          <v-col
            v-for="cbtItem,index in cbt"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            <v-card>
              <v-card
                height="150"
                color="#D14362"
              >
                <div class="d-flex justify-center">
                  <div>
                    <v-img
                      src="../../../assets/images/vector/subject.svg"
                      width="100%"
                    ></v-img>
                  </div>
                </div>
              </v-card>
              <v-card-text
                class="py-4"
              >
                <div class="mb-4">
                  <h2 class="font-weight-bold mb-4 black--text">
                    {{ cbtItem.name }}
                  </h2>
                </div>
                <div class="d-flex mb-2">
                  <div class="d-flex align-center mb-2">
                    <v-img
                      :src="icons.iconUser"
                      width="20"
                    ></v-img>
                    <span class="ml-4">Admin</span>
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="d-flex align-center mb-2">
                    <v-img
                      :src="icons.iconCalendar"
                      width="20"
                    ></v-img> <span class="ml-4">{{ cbtItem.start_date }}</span>
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="d-flex align-center mb-2">
                    <v-img
                      :src="icons.iconClock"
                      width="20"
                    ></v-img> <span class="ml-4">{{ cbtItem.duration }} Menit</span>
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="d-flex align-center mb-2">
                    <v-img
                      :src="icons.iconDocument"
                      width="20"
                    ></v-img> <span class="ml-4">{{ cbtItem.question.length }} Soal</span>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <span class="me-4">Token : </span> <h2>{{ cbtItem.token }}</h2>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row
          class="mb-5"
        >
          <v-col
            v-for="i,index in 3"
            :key="index"
            md="4"
          >
            <v-skeleton-loader
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div
        v-if="isEmpty"
        class="pa-4 text-center"
      >
        <v-img
          src="../../../assets/images/vector/cbt-empty.svg"
          class="mx-8 my-7"
        ></v-img>
        <p class="my-7">
          Belum ada CBT
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiArrowLeft, mdiDotsVertical } from '@mdi/js'
import iconCalendar from '../../../assets/icons/calendar.svg'
import iconClock from '../../../assets/icons/clock.svg'
import iconDocument from '../../../assets/icons/document-text.svg'
import iconUser from '../../../assets/icons/user.svg'

export default {
  name: 'Cbt',
  components: {},
  data() {
    return {
      icons: {
        iconDocument,
        iconUser,
        iconClock,
        iconCalendar,
        mdiDotsVertical,
        mdiArrowLeft,
      },
      service: 'cbt',
      cbt: [],
      isLoadingButton: false,
      isLoadingCard: true,
      isDisabledButton: false,
      isEmpty: false,
      confirmDialog: false,
      school: [],
      school_uuid: '',
      user_uuid: '',
    }
  },
  mounted() {
    this.getSchoolUuid()
    this.getCbt()
    localStorage.removeItem('cbt')
  },
  methods: {
    getCbt() {
      this.$services.ApiServices.list(this.service, { type_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.cbt = data.data
          this.isEmpty = false
          if (this.cbt.length < 1) {
            this.isEmpty = true
          }
          this.isLoadingCard = false
        },
        err => console.error(err),
      )
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
.btn-delete {
  color: #e30000 !important;
}
</style>
